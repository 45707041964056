<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>优惠券</el-breadcrumb-item>
      <el-breadcrumb-item>商家管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pkId"
            filterable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >商家名称：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入商家名称"
            v-model="query.shopName"
            clearable
          />
        </label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="shopInfoListcx"
          >查询</el-button
        >
        <el-button size="small" @click="shopInfoListcz()">重置</el-button>
      </div>
      <!-- 点击添加显示对话框 -->
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        @click="isShowAddClick"
        v-if="!$cookies.get('user')"
        >添加</el-button
      >
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        :loading="isShowImprot"
        @click="improtClick"
        v-if="!$cookies.get('user')"
        >导出</el-button
      >
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="shopName"
            label="商家名称"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="linkMan"
            label="联系人"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="tel"
            label="电话"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="address"
            label="地址"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.address ? scope.row.address : '无' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="modifyTime"
            label="添加时间"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.modifyTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="remarks"
            label="备注"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="200"
            fixed="right"
            v-if="!$cookies.get('user')"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="isShowCZMADialog(scope)"
                size="mini"
                icon="el-icon-edit"
                >重置密码</el-button
              >
              <el-button
                type="text"
                @click="isShoweditDialog(scope)"
                size="mini"
                icon="el-icon-edit"
                >编辑</el-button
              >
              <el-button
                type="text"
                @click="delClick(scope)"
                size="mini"
                icon="el-icon-delete"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加 -->
    <el-dialog
      title="添加商家"
      :visible.sync="isShowAdd"
      @close="addClose"
      width="50%"
      v-el-drag-dialog
    >
      <el-form
        :model="addForm"
        ref="addRef"
        :rules="formRules"
        label-width="100px"
      >
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="addForm.username"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="addForm.password"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="password1">
          <el-input
            v-model="addForm.password1"
            placeholder="请输入重复密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-row
          v-for="(item, index) in addForm.maxConfigCouponMoneyJson"
          :key="index"
        >
          <el-col :span="12">
            <el-form-item label="停车场">
              <span>{{ item.pkName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最高优惠额度">
              <el-input
                type="number"
                v-model="item.maxConfigCouponMoney"
                placeholder="请输入最高优惠额度"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="商家名称" prop="shopName">
          <el-input
            v-model="addForm.shopName"
            placeholder="请输入商家名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkMan">
          <el-input
            v-model="addForm.linkMan"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input
            v-model="addForm.tel"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="addForm.address"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            type="textarea"
            v-model="addForm.remarks"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="isShowAdd = false">取消</el-button>
          <el-button type="primary" @click="addClick">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑商家"
      :visible.sync="isShowEdit"
      width="50%"
      v-el-drag-dialog
    >
      <el-form
        :model="editForm"
        ref="editRef"
        :rules="formRules"
        label-width="100px"
      >
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="editForm.username"
            placeholder="请输入用户名"
            disabled
          ></el-input>
        </el-form-item>
        <el-row
          v-for="(item, index) in editForm.maxConfigCouponMoneyJson"
          :key="index"
        >
          <el-col :span="12">
            <el-form-item label="停车场">
              <span>{{ item.pkName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最高优惠额度">
              <el-input
                type="number"
                v-model="item.maxConfigCouponMoney"
                placeholder="请输入最高优惠额度"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="商家名称" prop="shopName">
          <el-input
            v-model="editForm.shopName"
            placeholder="请输入商家名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkMan">
          <el-input
            v-model="editForm.linkMan"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input
            v-model="editForm.tel"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="editForm.address"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            type="textarea"
            v-model="editForm.remarks"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="删除标记" prop="del"  v-if="false">
          <el-input
              v-model="editForm.delFlag"
              placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="isShowEdit = false">取消</el-button>
          <el-button type="primary" @click="editClick">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 重置密码 -->
    <el-dialog
      title="重置密码"
      :visible.sync="isShowEditCzmm"
      @close="czmmClose"
      width="50%"
      v-el-drag-dialog
    >
      <el-form
        :model="czmmForm"
        ref="czmmRef"
        :rules="formRules"
        label-width="100px"
      >
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="czmmForm.username"
            placeholder="请输入用户名"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="czmmForm.password"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="password2">
          <el-input
            v-model="czmmForm.password2"
            placeholder="请输入重复密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="isShowEditCzmm = false">取消</el-button>
          <el-button type="primary" @click="czmmClick">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  shopInfoList,
  deleteshopInfo,
  shopInfo,
  exportShopInfo,
  findByIdxx,
  updatePWD,
} from '@/api/coupon.js'
import { findPkInfoSelect } from '@/api/Parkinglot.js'
export default {
  data() {
    var checkphone = (rule, value, callback) => {
      // let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (!this.isCellPhone(value)) {
        // 引入methods中封装的检查手机格式的方法
        callback(new Error('请输入正确的手机号!'))
      } else {
        callback()
      }
    }
    var validatePass1 = (rule, value, callback) => {
      let result
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        var express =
          /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
        result = express.test(value) // 满足条件时候 正则结果是true
        if (result) {
          callback()
        } else {
          callback(new Error('最少8个字符，需包含大、小写字母、特殊字符和数字'))
        }
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.addForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var validatePass3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.czmmForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      isShowImprot: false,
      loading: false,
      isShowAdd: false,
      isShowEdit: false,
      isShowEditCzmm: false,
      isShowData: false,
      isShowQuery: false,
      tableData: [],
      tableDatatk: [],
      query: {
        // 查询条件
        current: 1,
        size: 10,
        pkId: undefined, // 停车场id
        shopName: undefined, // 商户名称
      },
      addForm: {
        username: undefined, // 账号
        password: undefined, // 密码
        password1: undefined, // 密码
        shopName: undefined, // 商家名称
        maxConfigCouponMoneyJson: [
          {
            pkName: undefined,
            pkIds: undefined, // 停车场
            maxConfigCouponMoney: undefined,
          },
        ],
        linkMan: undefined, // 联系人
        tel: undefined, // 联系电话
        address: undefined, // 详细地址
        remarks: undefined, // 备注
      },
      editForm: {
        id: undefined,
        username: undefined, // 账号
        shopName: undefined, // 商家名称
        delFlag: undefined,
        maxConfigCouponMoneyJson: [
          {
            pkName: undefined,
            pkIds: undefined, // 停车场
            maxConfigCouponMoney: undefined,
          },
        ],
        linkMan: undefined, // 联系人
        tel: undefined, // 联系电话
        address: undefined, // 详细地址
        remarks: undefined, // 备注
      },
      czmmForm: {
        username: undefined,
        password: undefined, // 密码
        password2: undefined, // 密码
      },
      formRules: {
        // 添加表单的验证规则
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, validator: validatePass1, trigger: 'blur' },
        ],
        password1: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
        password2: [
          { required: true, validator: validatePass3, trigger: 'blur' },
        ],
        pkId: [
          { required: true, message: '请选择停车场名称', trigger: 'blur' },
        ],
        pkIds: [
          { required: true, message: '请选择停车场名称', trigger: 'blur' },
        ],
        shopName: [
          { required: true, message: '请输入商家名称', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
        linkMan: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
        tel: [
          {
            required: true,
            validator: checkphone,
            trigger: ['blur', 'change'],
          },
        ],
      },
      maxConfigCouponMoneyJsons: undefined,
      total: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
    }
  },
  created() {
    this.findPkInfoSelect()
  },
  methods: {
    isCellPhone(val) {
      let mobileReg =
        /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/
      if (!mobileReg.test(val)) {
        return false
      } else {
        return true
      }
    },
    improtClick() {
      // 导出
      this.isShowImprot = true
      exportShopInfo(this.query).then((res) => {
        if (res.data.code === '200') {
          window.open(res.data.data.data)
        }
        this.isShowImprot = false
      })
    },
    shopInfoList() {
      // 进出记录查询列表
      shopInfoList(this.query).then((res) => {
        this.tableData = res.data.data.list
        this.total = res.data.data.total
        this.isShowData = true
        this.query.trueName = undefined
        this.query.mobile = undefined
        this.query.endTimeBegin = undefined
        this.query.endTimeEnd = undefined
        this.query.kindId = undefined
        this.loading = false
      })
    },
    shopInfoListcx() {
      // 查询
      this.query.current = 1
      this.shopInfoList()
    },
    shopInfoListcz() {
      // 重置
      this.query.pkId = this.PkInfoSelect[0].id
      this.query.shopName = ''
      this.shopInfoList()
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          this.query.pkId = this.PkInfoSelect[0].id
          this.maxConfigCouponMoneyJsons = this.PkInfoSelect.map((item) => {
            return {
              pkName: item.pkName,
              pkIds: item.id,
              maxConfigCouponMoney: undefined,
            }
          })
          this.addForm.maxConfigCouponMoneyJson = JSON.parse(
            JSON.stringify(this.maxConfigCouponMoneyJsons)
          )
          this.editForm.maxConfigCouponMoneyJson =
            this.maxConfigCouponMoneyJsons
          this.shopInfoList()
        }
      })
    },
    addClose() {
      // 监听添加用户对话框的关闭事件 清空input数据
      this.$refs.addRef.resetFields()
    },
    isShowAddClick() {
      this.isShowAdd = true
      this.addForm.maxConfigCouponMoneyJson = JSON.parse(
        JSON.stringify(this.maxConfigCouponMoneyJsons)
      )
    },
    addClick() {
      this.$refs.addRef.validate((valid) => {
        if (!valid) return
        this.addForm.maxConfigCouponMoneyJson =
          this.addForm.maxConfigCouponMoneyJson.filter(
            (item) => item.maxConfigCouponMoney
          )
        if (this.addForm.maxConfigCouponMoneyJson.length == 0) {
          this.addForm.maxConfigCouponMoneyJson = JSON.parse(
            JSON.stringify(this.maxConfigCouponMoneyJsons)
          )
          return this.$message.warning('所属停车场必填')
        }
        shopInfo(this.addForm).then((res) => {
          if (res.data.code === '200') {
            if (res.data.data === false) {
              this.$message.warning('商家用户名重复，请重新注册')
            } else {
              this.isShowAdd = false
              this.$message.success('添加成功')
              this.shopInfoList()
            }
          } else {
            this.$message.success(res.data.msg)
          }
        })
      })
    },
    isShoweditDialog(scope) {
      // 编辑显示弹框
      findByIdxx({ id: scope.row.id }).then((res) => {
        if (res.data.code === '200') {
          this.editForm.id = res.data.data.id
          this.editForm.username = res.data.data.userName
          this.editForm.shopName = res.data.data.shopName
          this.editForm.linkMan = res.data.data.linkMan
          this.editForm.tel = res.data.data.tel
          this.editForm.address = res.data.data.address
          this.editForm.remarks = res.data.data.remarks
          this.editForm.delFlag = res.data.data.delFlag
          this.editForm.maxConfigCouponMoneyJson =
            res.data.data.maxConfigCouponMoneyJson.concat(
              this.maxConfigCouponMoneyJsons
            )
          // 对象数组去重
          let myMap = new Map()
          this.editForm.maxConfigCouponMoneyJson =
            this.editForm.maxConfigCouponMoneyJson.filter(
              (item) => !myMap.has(item.pkIds) && myMap.set(item.pkIds, item)
            )
          this.isShowEdit = true
        }
      })
    },
    editClick() {
      // 编辑
      this.$refs.editRef.validate((valid) => {
        if (!valid) return
        this.editForm.maxConfigCouponMoneyJson =
          this.editForm.maxConfigCouponMoneyJson.filter(
            (item) => item.maxConfigCouponMoney
          )
        if (this.editForm.maxConfigCouponMoneyJson.length == 0) {
          this.editForm.maxConfigCouponMoneyJson = JSON.parse(
            JSON.stringify(this.maxConfigCouponMoneyJsons)
          )
          return this.$message.warning('所属停车场必填')
        }
        shopInfo(this.editForm).then((res) => {
          if (res.data.code === '200') {
            this.$message.success('编辑成功')
            this.shopInfoList()
            this.isShowEdit = false
          }
        })
      })
    },
    czmmClose() {
      // 监听添加用户对话框的关闭事件 清空input数据
      this.$refs.czmmRef.resetFields()
    },
    isShowCZMADialog(scope) {
      findByIdxx({ id: scope.row.id }).then((res) => {
        if (res.data.code === '200') {
          this.czmmForm.username = res.data.data.userName
          this.isShowEditCzmm = true
        }
      })
    },
    czmmClick() {
      if (this.czmmForm.password && !this.czmmForm.password2) {
        return this.$message.warning('请输入重复密码')
      }
      if (!this.czmmForm.password && this.czmmForm.password2) {
        return this.$message.warning('请输入密码')
      }
      updatePWD(this.czmmForm).then((res) => {
        if (res.data.code === '200') {
          this.$message.success('修改成功')
          this.shopInfoList()
          this.isShowEditCzmm = false
        }
      })
    },
    delClick(scope) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteshopInfo({ id: scope.row.id }).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('删除成功')
              this.shopInfoList()
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    handleSizeChange(val) {
      this.loading = true
      this.query.size = val
      this.shopInfoList()
    },
    handleCurrentChange(val) {
      this.loading = true
      this.query.current = val
      this.shopInfoList()
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
